.icon{
    font-size: 24px;
    margin-right: 0.2em;
}

.folderName{
    cursor: pointer;
    user-select:none;
}
.folderName:hover {
    background-color: rgba(248, 249, 250);
}
.divider{
    user-select: none;
    margin: 0.5em;
}