/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.bi-file-text, .bi-folder-fill,.bi-hdd{
  vertical-align: middle;
  font-size: 24px;
  margin-right: 0.2em;
}
.list-group-item-action{
  cursor: unset !important;
}
#browser{
  height: 100vh;
  padding-bottom: 1em;
}
#browser-container {
  height: 100%;
  display: grid;
  column-gap: 10px;
  row-gap: 15px;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 50px 50px auto 1fr 50% auto;
  grid-template-areas:
    "header header"
    "folderTree breadcrumb"
    "folderTree actionArea"
    "folderTree content"
    "uploadList content"
    "fileProgress fileProgress";
}
#sizeHeader{
  margin-left:-24px ;
}
@media (max-width: 768px){
  #browser-container {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 50px auto 1fr auto;
    grid-template-areas:
      "header"
      "breadcrumb"
      "actionArea"
      "content"
      "fileProgress";
  }
  #uploadList{
    display: none;
  }
  #folderTree {
    display: none;
  }
  #sizeHeader{
    display: none;
  }
}

#uploadList{
  grid-area: uploadList;
  overflow: auto;
}
#content {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: bottom right;
  grid-area: content;
}
#content .list-group{
  height: calc(100% - 50px);
  overflow: auto;
}
#breadcrumb {
  grid-area: breadcrumb;
  display: flex;
  align-items: center;
  overflow: auto;
  text-wrap: nowrap;
}
#folderTree {
  grid-area: folderTree;
  overflow: auto;
}
#actionArea {
  grid-area: actionArea;
}
#header {
  grid-area: header;
}
#fileProgress {
  grid-area: fileProgress;
}
