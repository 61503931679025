.plexButton {
    display: flex !important;
    align-items: center;
    gap: 0.25rem;
    background-color: #27292c !important;
    color: #f9be03 !important;

    @media (min-width: 768px) { 
        &::after { content: attr(title); }
    }

    > svg { width: 1rem; }
}