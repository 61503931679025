.UploadButton {
    display: flex !important;
    align-items: center;
    gap: 0.25rem;

    @media (min-width: 768px) { 
        &::after { content: attr(title); }
    }

    > svg { width: 1rem; }
}