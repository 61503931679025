.folderName {
    margin-bottom: unset !important;
    user-select: none;
}

.li {
    padding-inline-start: 10px;
}
.div{
    display: flex;
    align-items: center;
}
.div:hover{
    background-color: rgba(248, 249, 250);
}

.button {
    border: none;
    background-color: unset;
    margin: unset;
    padding: unset;
    transition: transform .35s ease;
    transform-origin: 0.3em 50%;
}

.button[aria-expanded="true"] {
    transform: rotate(90deg);
}

.emptyFolder {
    margin-left: 16px;
}

.ul{
    list-style: none;
  
    padding-inline-start: unset;
}